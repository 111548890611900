.h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 57.6px;
  color: $text-color-secondary;
}

.label-field {
  font-size: 11px;
  font-weight: 600;
  line-height: 16.5px;
  letter-spacing: 1px;
  text-align: left;
}

.label-asterisk {
  color: #cc3939;
  font-size: 20px;
  font-weight: 600;
  height: 18px;
}
