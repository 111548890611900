/* You can add global styles to this file, and also import other style files */
@import 'node_modules/primeng/resources/themes/lara-light-blue/theme.css';
@import 'node_modules/primeng/resources/primeng.css';
@import 'node_modules/primeflex/primeflex.css';
@import './variables';
@import './colors';
@import './body';
@import './input-dropdown';
@import './input';
@import './input-multiselect';
@import './toastr';
@import './typography';
@import './button';
@import './footer';
