.p-toast .p-toast-message.p-toast-message-info {
  background: #d7effa;
  border: solid #3b82f6;
  border-width: 0 0 0 6px;
  color: #3b82f6;

  & .p-toast-message-icon,
  .p-toast-icon-close {
    color: #3b82f6;
  }
}
