.p-dropdown.p-component.p-inputwrapper {
  border-color: $border-color;
  width: 100% !important;

  & .p-inputtext {
    color: $text-color-secondary;

    &.p-placeholder {
      color: $text-color-primary;
    }
  }
}

p-dropdown.ng-invalid.ng-dirty {
  & .p-dropdown.p-component.p-inputwrapper {
    border-color: $error-color;
  }
}
