input {
  border-color: $border-color;
  color: $text-color-secondary;

  &.p-inputtext[disabled] {
    background-color: #5d6e80;
    color: black;
  }

  &.ng-invalid.ng-dirty {
    border-color: $error-color;
  }
}

.p-inputnumber.p-component {
  width: 100%;
}

p-calendar.ng-invalid.ng-dirty {
  & input {
    border-color: $error-color;
  }
}
