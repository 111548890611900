body,
body .p-component,
.p-element {
  font-family: $font-family;
}

body {
  height: 100%;
  padding-left: 20%;
  padding-right: 20%;
  color: $text-color-primary;
}

@media screen and (max-width: 768px) {
  body {
    padding-left: 5%;
    padding-right: 5%;
  }
}
